/*CORE*/
body, html {
  margin: 0;
  padding: 0;
  font-size: 13pt;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5;
}

.content-wrapper {
  padding-top: 20px;
}

/*CORE-TOOLBAR*/
.toolbar-header {
  height: 40px;
}

.header-content {
  margin-top: 10px;
}

.header-title {
  float: left;
  margin-top: -23px;
  margin-left: 20px;
}

.menu-left {
  float: left;
  margin-left: -30px;
  cursor: pointer;
}

.menu-right {
  float: right;
  margin-right: -30px;
  cursor: pointer;
}

.menu-drawer .ant-drawer-header {
  background-color: var(--primary);
  height: 41px;
  border-radius: 0;
}

.menu-drawer .ant-drawer-title {
  color: var(--white);
  margin-top: -5px;
}

.menu-drawer .ant-menu .ant-menu-item-selected {
  color: var(--primary);
  font-weight: bold;
}

.menu-drawer .ant-menu-item:hover {
  color: var(--primary);
}

.menu-drawer .ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right-color: var(--primary);
}

a {
  text-decoration: none;
  color: inherit;
}